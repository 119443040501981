// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-complete-js": () => import("./../../../src/pages/contact-complete.js" /* webpackChunkName: "component---src-pages-contact-complete-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

